var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("back-button", { attrs: { to: "/admin/" } }, [
        _vm._v("Back to Settings"),
      ]),
      _c(
        "b-card",
        {
          staticStyle: { "max-width": "1000px" },
          style: _vm.loading ? "cursor:wait" : "cursor:default",
        },
        [
          _c(
            "div",
            [
              _c(
                "b-alert",
                {
                  attrs: {
                    variant: _vm.success ? "success" : "danger",
                    dismissible: "",
                  },
                  model: {
                    value: _vm.showAlert,
                    callback: function ($$v) {
                      _vm.showAlert = $$v
                    },
                    expression: "showAlert",
                  },
                },
                [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
              ),
            ],
            1
          ),
          _c("div", { staticClass: "avo-header-text mb-4" }, [
            _vm._v(" Taxonomy CSV Upload "),
          ]),
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit } },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-file", {
                    attrs: {
                      state: Boolean(_vm.file),
                      placeholder: "Choose a file or drop it here...",
                      "drop-placeholder": "Drop file here...",
                    },
                    model: {
                      value: _vm.file,
                      callback: function ($$v) {
                        _vm.file = $$v
                      },
                      expression: "file",
                    },
                  }),
                  _c("div", { staticClass: "mt-3" }, [
                    _vm._v(
                      "Selected file: " + _vm._s(_vm.file ? _vm.file.name : "")
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-primary-btn",
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.onSubmit },
                    },
                    [_vm._v("Submit")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }